import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Article,
  Chat,
  CircleInformation,
  Info,
} from "grommet-icons";
import { Icon, TipContent } from "@/cloverleaf-ui/components";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import { Button } from "@/components/v2/Button";
import { Menu } from "@/components/v2/Menu";
import { identify } from "@/lib/analytics";
import { EventNames } from "@/lib/analytics/constants";
import { useLocale } from "@/lib/hooks";

const GET_HELP_MENU_FALLBACK_DATA_QUERY = gql`
  query getHelpMenuFallbackData {
    currentUser {
      id
      email
      fullName
      intercomId
      organization {
        id
      }
    }
  }
`;

function HelpMenu() {
  const { t } = useLocale();
  const [intercomLoaded, setIntercomLoaded] = useState(false);

  useEffect(() => {
    const checkIntercom = setInterval(() => {
      if (window.Intercom) {
        setIntercomLoaded(true);
        clearInterval(checkIntercom);
      }
    }, 1000); // check every second

    return () => clearInterval(checkIntercom);
  }, []);

  const { data } = useQuery(GET_HELP_MENU_FALLBACK_DATA_QUERY, {
    onCompleted: (data) => {
      const intercomId = data?.currentUser?.intercomId;

      if (!window.intercomSettings) {
        window.intercomSettings = {
          "user_hash": intercomId,
        };
      } else if (!window.intercomSettings.user_hash) {
        window.intercomSettings.user_hash = intercomId;
      }

      identify(data?.currentUser?.id, {
        email: data?.currentUser?.email,
      }, {
        integrations: {
          Intercom: {
            user_hash: intercomId,
          },
        },
      })
    },
  });

  if (intercomLoaded) {
    return (
      <Button
        a11yTitle={t("support")}
        alignSelf="center"
        icon={(
          <Icon
            icon="circle-question"
            color="grey400"
            size="lg"
          />
        )}
        logEventProps={{
          elementName: "intercom-help-button",
          eventName: EventNames.HelpIconClicked,
        }}
        onClick={() => window.Intercom("show")}
        plain
        tip={{
          content: <TipContent message={t("support")} position="bottom" />,
          plain: true,
        }}
      />
    );
  }

  const {
    id,
    email,
    fullName,
    organization,
  } = data?.currentUser || {};

  const menuItems = [
    {
      gap: "small",
      reverse: true,
      justify: "between",
      target: "_blank",
      icon: <CircleInformation size="small" />,
      label: t("help-center"),
      href: "https://help.cloverleaf.me/en/",
    },
    {
      gap: "small",
      reverse: true,
      justify: "between",
      target: "_blank",
      icon: <Info size="small" />,
      label: t("product-guides"),
      href: "https://help.cloverleaf.me/en/collections/5624206-cloverleaf-learning-resources-guides",
    },
    {
      gap: "small",
      reverse: true,
      justify: "between",
      target: "_blank",
      icon: <Article size="small" />,
      label: t("blog"),
      href: "https://cloverleaf.me/blog",
    },
    {
      gap: "small",
      reverse: true,
      justify: "between",
      target: "_blank",
      icon: <Chat size="small" />,
      label: t("product-feedback"),
      href: `https://cloverleafme.typeform.com/to/SPdnDHM0#user_id=${id}&email=${email}&name=${fullName}&organizationid=${organization?.id}`,
    },
  ];

  return (
    <Menu
      alignSelf="center"
      dropAlign={DROP_POSITION.bottom}
      dropProps={{ trapFocus: false }}
      icon={(
        <Icon
          icon="circle-question"
          color="grey400"
          size="lg"
        />
      )}
      items={[...menuItems]}
      logEventProps={{
        elementName: "help-menu",
        eventName: EventNames.HelpIconClicked,
      }}
      plain
      tip={{
        content: <TipContent message={t("support")} position="bottom" />,
        plain: true,
      }}
    />
  );
}

export { HelpMenu };
