import { useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { parseCookies } from "nookies";
import { publicRuntimeConfig } from "../config";
import { BASE_URL } from "../constants";

export const validateSessionCookie = async (cookie, cookiesHeader, baseUrl) => {
  if (cookie) {
    try {
      const { userId } = JSON.parse(cookie);

      if (!userId) {
        return false;
      }

      let fetchUrl;

      if (publicRuntimeConfig.CLOVERLEAF_ENV !== "development") {
        // In production, use baseUrl if available, otherwise use relative URL
        fetchUrl = baseUrl
          ? `${baseUrl}/api/user/is-authenticated`
          : "/api/user/is-authenticated";
      } else {
        // In development, use the BASE_URL
        fetchUrl = `${BASE_URL}/api/user/is-authenticated`;
      }

      const response = await fetch(fetchUrl, {
        headers: {
          "Content-Type": "application/json",
          "Cookie": cookiesHeader,
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch authentication status");
      }

      const data = await response.json();

      if (!data?.isAuthenticated) {
        return false;
      }

      return userId;
    } catch (error) {
      Sentry.captureException(error, {
        message: "Failed to validate session cookie",
      });
      console.error(error);
    }
  }

  return false;
};

const checkAuthStatus = async (cookie, cookiesHeader, baseUrl) => {
  return validateSessionCookie(cookie, cookiesHeader, baseUrl);
};

export const checkAuthStatusFromContext = async (ctx) => {
  if (!ctx?.req) {
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Missing context from authentication status check",
      level: "warning",
      data: {
        ctx: ctx ? "present" : "missing",
        req: ctx?.req ? "present" : "missing",
        headers: ctx?.req?.headers ? "present" : "missing",
        cookies: ctx?.req?.headers?.cookie ? "present" : "missing",
        method: ctx?.req?.method || "unknown",
        url: ctx?.req?.url || "unknown"
      }
    })
  }

  const cookies = parseCookies(ctx);
  const sessionCookie = cookies[publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID];
  const cookiesHeader = ctx.req ? ctx.req.headers.cookie : "";

  let baseUrl;

  if (ctx?.req && publicRuntimeConfig.CLOVERLEAF_ENV !== "development") {
    // In production, construct baseUrl from request headers
    const protocol =
      ctx.req.headers["x-forwarded-proto"] ||
      (ctx.req.connection.encrypted ? "https" : "http");
    const host = ctx.req.headers.host;
    baseUrl = `${protocol}://${host}`;
  } else {
    // In development, use BASE_URL
    baseUrl = BASE_URL;
  }

  const authenticated = await checkAuthStatus(
    sessionCookie,
    cookiesHeader,
    baseUrl
  );

  return authenticated;
};

export const useAuth = (initialAuthStatus) => {
  const [authenticated, setAuthenticated] = useState(initialAuthStatus);

  useEffect(() => {
    const authenticateUser = async () => {
      const cookies = parseCookies();
      const sessionCookie = cookies[publicRuntimeConfig.CLOVERLEAF_COOKIE_USER_ID];

      const cookiesHeader = document.cookie;

      const userId = await validateSessionCookie(sessionCookie, cookiesHeader);

      if (!userId) {
        setAuthenticated(false);
      } else {
        setAuthenticated(userId);
      }
    };

    authenticateUser();
  }, []);

  return authenticated;
};
