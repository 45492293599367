import Router from "next/router";
import { gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import isUndefined from "lodash/fp/isUndefined";
import omitBy from "lodash/fp/omitBy";
import { parseCookies } from "nookies";
import { initializeApollo } from "./apolloClient";
import { analytics } from "./segment";

const GET_CURRENT_USER_ORGANIZATION_ID_QUERY = gql`
  query getCurrentUserOrganizationId {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;

const omitUndefined = omitBy(isUndefined);

// For local/test environment, do NOT use the real id, it can corrupt prod analytics
const formatId = (id) => {
  if (process.env.NODE_ENV !== "production") {
    return `test_${id}`;
  }

  return id;
};

const getView = (user) => {
  if (user?.organization === undefined) return undefined;
  if (user?.organization === null) return "Personal";

  return user?.organization?.name;
};

const formatCompany = (user) => {
  if (user.organization) {
    return {
      id: user.organization?.id,
      name: user.organization?.name,
    };
  }

  return undefined;
};

const formatOrganization = (user) => {
  if (user.organization?.name) return user.organization.name;
  if (user.organization?.alias) return user.organization.alias;
  if (user.primaryOrganization?.name) return user.primaryOrganization.name;

  return undefined;
};

const formatUserForSegment = (user) => {
  const company = formatCompany(user);
  const organization = formatOrganization(user);
  const organizationId = user?.organization?.id;

  return omitUndefined({
    email: user.email,
    name: user.fullName,
    firstName: user.firstName,
    lastName: user.lastName,
    organization,
    organizationId,
    company, // uses organization attributes
    companyName: user.companyName,
    title: user.role,
    industry: user.industry,
    avatar: user.avatar,
    type: user.teamUserType,
    accountType: user.group,
    isCandidate: !!user.isCandidate,
    isTest: process.env.NODE_ENV === "production" ? undefined : true,
    source: user.source || user.originalSource,
    organizationView: getView(user),
    inCoachOrganization: user.inCoachOrganization,
    isLead: user.isLead,
    inactive: user.inactive, // is this user an inactive user, such as from the bot flow?
    locale: user.locale,
    expEnneagramProductTour: user.expEnneagramProductTour,
    isPLG: user.isPLG,
    "survey-intent": user["survey-intent"],
    "survey-department": user["survey-department"],
    "survey-role": user["survey-role"],
    "survey-team-size": user["survey-team-size"],
    "survey-organization-size": user["survey-organization-size"],
  });
};

// Wraps a function in an error catcher so we don't ever crash for analytics problems
const wrap = func => (...args) => {
  try {
    func(...args);
  }
  catch (error) {
    Sentry.captureException(error, { extra: { message: "ANALYTICS ERROR" }, level: "warning" });
  }
};

// Utility function to get current consent preferences from cookies because we can't use hooks
const getConsentPreferences = async () => {
  try {
    const cookies = parseCookies();

    const user = await analytics.user();

    if (user.id()) {
      // Authenticated user has given consent
      return {
        advertising: true,
        analytics: true,
        functional: true,
      };
    }

    const trackingPreferences = cookies["tracking-preferences"];

    if (!trackingPreferences) {
      return {
        advertising: false,
        analytics: false,
        functional: false,
      }; // Default to false if no preferences are set
    }

    const parsedPreferences = JSON.parse(decodeURIComponent(trackingPreferences));

    return parsedPreferences?.consent?.categoryPreferences || {
      advertising: false,
      analytics: false,
      functional: false,
    };
  } catch (error) {
    console.error("Error parsing tracking preferences:", error);

    return {
      advertising: false,
      analytics: false,
      functional: false,
    };
  }
};

// Helper function to wrap analytics.track with consent context
const trackWithConsent = (eventName, properties = {}, options = {}, callback) => {
  const consentPreferences = getConsentPreferences();

  // Call the analytics.track method with consent context included
  analytics.track(
    eventName,
    properties,
    {
      ...options,
      context: {
        ...options.context,
        consent: {
          categoryPreferences: consentPreferences,
        },
      },
    },
    callback
  );
};

// Helper function to wrap analytics.identify with consent context
export const identifyWithConsent = (userId, traits = {}, options = {}, callback) => {
  const consentPreferences = getConsentPreferences();

  // Call the analytics.identify method with consent context included
  analytics.identify(
    userId,
    traits,
    {
      ...options,
      context: {
        ...options.context,
        consent: {
          categoryPreferences: consentPreferences,
        },
      },
    },
    callback
  );
};

export const track = wrap(async (event, props) => {
  if (typeof window === "undefined") return;

  const properties = props || {};

  properties.platform = properties.platform || "in-app";

  const apolloClient = initializeApollo();

  try {
    const contextOrganizationId = Router?.query?.groupId || Router?.query?.organizationId;
    const eventProperties = { ...properties };

    if (
      // If tracking included an organizationId...
      eventProperties.organizationId
      // and if the router organizationId is not the same...
      && eventProperties.organizationId !== contextOrganizationId
    ) {
      // Set our organizationId as context
      eventProperties.contextOrganizationId = contextOrganizationId;
    } else {
      eventProperties.organizationId = contextOrganizationId;
    }

    // Without an organizationId, try to get one from currentUser.organizationId
    if (!eventProperties.organizationId) {
      try {
        const { data } = await apolloClient.query({
          query: GET_CURRENT_USER_ORGANIZATION_ID_QUERY,
          // Cache-only to prevent redirect when tracking outside authentication
          fetchPolicy: "cache-only",
        });

        if (data?.currentUser?.organization?.id) {
          eventProperties.organizationId = data.currentUser.organization.id;
        }
      } catch (e) {
        // Could not query for organizationId
        console.error(e);
      }
    }

    trackWithConsent(event, eventProperties);
  } catch (e) {
    // We couldn't record the analytic, fallback to normal logging
    console.error(e);

    trackWithConsent(event, properties);
  }
});

export const alias = wrap((userId) => {
  if (typeof window === "undefined") return;
  analytics.alias(formatId(userId));
});

export const identify = wrap((id, user, options) => {
  if (typeof window === "undefined") return;
  identifyWithConsent(formatId(id), formatUserForSegment(user || {}), options);
});

export const reset = wrap(() => {
  if (typeof window === "undefined") return;
  if (window.Intercom) window.Intercom("shutdown")
  analytics.ready(() => {
    if (window.mixpanel && window.mixpanel.cookie && window.mixpanel.cookie.clear) {
      window.mixpanel.cookie.clear();
    }
  });
  analytics.reset();
});

export const getAnonId = wrap(() => {
  if (typeof window === "undefined") return Promise.resolve();
  const promise = new Promise((resolve) => {
    analytics.ready(() => {
      const anonId = window.mixpanel.get_distinct_id();
      resolve(anonId);
    });
  });

  return promise;
});
