import { ThemeContext } from "grommet";
import { Button } from "@/components/v2/Button";

const BLUE_PILL_BUTTON_THEME = {
  button: {
    active: {
      default: {
        color: "white",
        background: "blue600",
      },
    },
    default: {
      background: "blue500",
      border: {
        color: "blue500",
        radius: "18px",
      },
      color: "blue700",
      padding: {
        horizontal: "small",
        vertical: "xxsmall",
      },
    },
    hover: {
      default: {
        background: "blue600",
        border: {
          color: "blue600",
        },
        color: "white",
      },
    },
  }
};

function PillButton(props) {
  return (
    <ThemeContext.Extend value={BLUE_PILL_BUTTON_THEME}>
      <Button {...props} />
    </ThemeContext.Extend>
  );
}

export { PillButton };
