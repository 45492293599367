import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import inRegions from "@segment/in-regions";
import { useCookieStorage } from "@/cloverleaf-ui/hooks";
import { defaultPreferences } from "./constants";
import { analytics } from "@/lib/segment";

const ConsentManagerContext = createContext({
  showPreferencesDialog: false,
  showConsentBanner: false,
  openPreferencesDialog: () => {},
  closePreferencesDialog: () => {},
  closeConsentBanner: () => {},
  openConsentBanner: () => {},
  cookiePreferences: {},
  setCookiePreferences: () => {},
});

export const ConsentManagerProvider = ({ children, isAuthenticated }) => {
  const [trackingCookie, setTrackingCookie] = useCookieStorage("tracking-preferences");

  const [showPreferencesDialog, setShowPreferencesDialog] = useState(false);
  const [showConsentBanner, setShowConsentBanner] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const inConsentRequiredRegion = inRegions(["CA", "EU"]);

      // If authenticated user, their registration is considered absolute consent. Do not show banner.
      if (!isAuthenticated) {
        if (
          // inConsentRequiredRegion() && // uncomment if we only show banner for specific regions
          !trackingCookie?.consent?.categoryPreferences // if they do not have any category preferences we must show the banner
          || trackingCookie?.pristine === true // this flag indicates the preferences were saved by default, we need the user to confirm consent
        ) {
          setShowConsentBanner(true);
        }
      }
    }
  }, [isAuthenticated, trackingCookie]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // If authentiated, the user is considered to have given absolute consent. Make sure tracking-preferences reflect that.
      if (isAuthenticated && trackingCookie?.consent?.categoryPreferences) {
        const currentPreferences = trackingCookie.consent.categoryPreferences;
        const newPreferences = defaultPreferences;

        // Don't update cookie unneccessarily
        if (JSON.stringify(currentPreferences) !== JSON.stringify(newPreferences)) {
          setTrackingCookie({
            version: 1,
            pristine: false, // this is intentionally false because authenticated users accepted consent
            consent: {
              categoryPreferences: newPreferences,
            },
          });

          // Track consent update in Segment
          analytics.track("Consent Preferences Updated", {}, {
            context: {
              consent: {
                categoryPreferences: newPreferences,
              },
            },
          });
        }
      }
    }
  }, [isAuthenticated, trackingCookie, setTrackingCookie]);

  const openPreferencesDialog = () => setShowPreferencesDialog(true);
  const closePreferencesDialog = () => setShowPreferencesDialog(false);
  const openConsentBanner = () => setShowConsentBanner(true);
  const closeConsentBanner = () => setShowConsentBanner(false);
  const setCookiePreferences = (v) => setTrackingCookie(v);

  return (
    <ConsentManagerContext.Provider value={{
      showPreferencesDialog,
      showConsentBanner,
      openPreferencesDialog,
      closePreferencesDialog,
      openConsentBanner,
      closeConsentBanner,
      cookiePreferences: trackingCookie,
      setCookiePreferences,
    }}
    >
      {children}
    </ConsentManagerContext.Provider>
  );
};

// Custom hook for consuming the context
export const useConsentManager = () => useContext(ConsentManagerContext);
