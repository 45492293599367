import { useEffect } from "react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import { identify } from "@/lib";
import { analytics } from "@/lib/segment";

const GET_CURRENT_USER_DETAILS_FOR_ANALYTICS = gql`
  query getCurrentUserTraitsForAnalytics {
    currentUser {
      id
      email
      firstName
      lastName
      fullName
      isCandidate
      isLead
      intercomId
      organization {
        id
        name
      }
    }
  }
`;

function Analytics({ isAuthenticated }) {
  const router = useRouter();

  const { loading, data } = useQuery(GET_CURRENT_USER_DETAILS_FOR_ANALYTICS, {
    fetchPolicy: "cache-first",
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if (!loading && data) {
      const {
        id,
        email,
        firstName,
        lastName,
        fullName,
        isCandidate,
        isLead,
        organization,
        intercomId,
      } = data.currentUser;

      identify(id, {
        email,
        firstName,
        lastName,
        fullName,
        isCandidate,
        isLead,
        companyName: organization?.name,
      },
      {
        integrations: {
          Intercom: {
            user_hash: intercomId,
          }
        },
      });
    }
  }, [loading, data]);

  useEffect(() => {
    const handleRouteChange = () => {
      analytics.page();
    };

    handleRouteChange();

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export { Analytics };
