import React from "react";
import {
  Anchor,
  Box,
  Button,
  Grommet,
  Text,
} from "grommet";
import { Notification } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { useConsentManager } from "./ConsentManagerContext";
import { CATEGORIES } from "./constants";
import { analytics } from "@/lib/segment";

const ConsentBanner = () => {
  const { openPreferencesDialog, closeConsentBanner, showConsentBanner, setCookiePreferences } = useConsentManager();

  if (!showConsentBanner) return null;

  const handleSavePreferences = (preferences) => {
    const consentPreferences = {
      advertising: preferences.advertising,
      marketingAndAnalytics: preferences.marketingAndAnalytics,
      functional: preferences.functional,
    };

    setCookiePreferences({
      version: 1,
      pristine: false,
      consent: {
        categoryPreferences: consentPreferences,
      },
    });

    // Track consent update in Segment
    analytics.then(([response]) => {
      response.track("Consent Preferences Updated", {}, {
        context: {
          consent: {
            categoryPreferences: consentPreferences,
          },
        },
      });
    }).then(() => closeConsentBanner());
  };

  const handleAllowAll = () => {
    const preferences = {
      [CATEGORIES.functional]: true,
      [CATEGORIES.marketingAndAnalytics]: true,
      [CATEGORIES.advertising]: true,
    };

    return handleSavePreferences(preferences);
  };

  const handleDenyAll = () => {
    const preferences = {
      [CATEGORIES.functional]: false,
      [CATEGORIES.marketingAndAnalytics]: false,
      [CATEGORIES.advertising]: false,
    };

    return handleSavePreferences(preferences);
  };

  return (
    <Grommet theme={themeStyle} style={{ position: "absolute", width: "100%", zIndex: 9999999 }}>
      <Notification
        global
        status="normal"
        onClose={closeConsentBanner}
        visible
        toast={false}
        message={(
          <Box direction="row-responsive" gap="medium">
            <Box>
              <Text>
                We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using our website, you’re agreeing to the collection of data as described in our{" "}
                <Anchor
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cloverleaf.me/privacy"
                  style={{ textDecoration: "underline" }}
                >
                  Website Data Collection Policy
                </Anchor>.{" "}
                <Anchor color="white" href="#" onClick={openPreferencesDialog} style={{ textDecoration: "underline" }}>
                  You can manage your preferences here!
                </Anchor>
              </Text>
            </Box>
            <Box direction="column" flex="grow" gap="small" width={{ min: "medium", max: "350px" }}>
              <Button primary label="Allow All" onClick={handleAllowAll} style={{ border: "1px solid white", height: "35px", padding: "4px 22px" }} />
              <Button secondary label="Deny All" onClick={handleDenyAll} style={{ background: "white", height: "35px", padding: "4px 22px", color: "#676767" }} />
            </Box>
          </Box>
        )}
      />
    </Grommet>
  );
};

export { ConsentBanner };
