import { AnalyticsBrowser } from "@segment/analytics-next";
import { publicRuntimeConfig } from "./config";

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: publicRuntimeConfig.SEGMENT_WRITE_KEY,
    cdnURL: "https://analytics.cloverleaf.me",
    destinations: ["Intercom"],
    plugins: ["intercom"],
  },
  {
    integrations: {
      "All": true,
      // This is throwing a console warning, but it is REQUIRED and I have no further information or documentation.
      // This is being deprecated and will not be available in future releases of Analytics JS.
      "Segment.io": {
        apiHost: "track.cloverleaf.me/v1",
      },
    },
    // Disable for testing
    disable: process.env.NODE_ENV === "test"
  },
);
