import React from "react";
import {
  Anchor,
  Box,
  Footer as GrommetFooter,
  Image,
  Text,
} from "grommet";
import LanguageChooser from "@/components/composite/Internationalization/LanguageChooser";
import { AnchorLink } from "@/components/v2/AnchorLink";
import { useConsentManager } from "@/components/v2/ConsentManager/ConsentManagerContext";
import { useLocale, useAuth } from "@/lib/hooks";

function Footer(props) {
  const authenticated = useAuth();
  const { openPreferencesDialog } = useConsentManager();

  const { t } = useLocale();

  const handleOnClickDataCollection = (event) => {
    event.preventDefault();

    return openPreferencesDialog();
  };

  return (
    <GrommetFooter background="#4A4A4A" fill="horizontal" margin="none" pad="xsmall" {...props}>
      <Box>
        <Text size="xsmall">
          Copyright &reg; {new Date().getUTCFullYear()} Cloverleaf, Inc.
        </Text>
        <AnchorLink color="text" href="https://cloverleaf.me/terms" size="xsmall" target="_blank">
          {t("common:footer.terms-of-service")}
        </AnchorLink>
        <Box direction="row" wrap>
          <AnchorLink color="text" href="https://cloverleaf.me/privacy" margin={{ right: "xsmall" }} size="xsmall" target="_blank">
            {t("common:footer.privacy-policy")}
          </AnchorLink>
          {!authenticated && (
            <Anchor color="text" href="#" onClick={handleOnClickDataCollection} size="xsmall">
              {t("common:footer.data-collection")}
            </Anchor>
          )}
        </Box>
      </Box>
      <LanguageChooser />
      <Box width={{ max: "150px" }}>
        <Image
          fit="contain"
          alt="Powered by Cloverleaf"
          fill={false}
          src="https://cloverleaf-app-publicfiles.s3.amazonaws.com/Cloverleaf-Banner-Inverse.png"
        />
      </Box>
    </GrommetFooter>
  );
}
export { Footer };
