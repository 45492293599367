import React, { useState, useEffect } from "react";
import inRegions from "@segment/in-regions";
import {
  Box,
  Button,
  Text,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  RadioButtonGroup,
  Grommet,
  Heading,
} from "grommet";
import { Modal } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { AnchorLink } from "../AnchorLink";
import { useConsentManager } from "./ConsentManagerContext";
import {
  caDefaultPreferences,
  CONSENT_PREFERENCES,
  defaultPreferences,
  euDefaultPreferences,
  preferencesDialogContent,
  preferencesDialogTitle
} from "./constants";
import { analytics } from "@/lib/segment";

const ConsentManager = ({ isAuthenticated }) => {
  const {
    showPreferencesDialog,
    closePreferencesDialog,
    closeConsentBanner,
    cookiePreferences,
    setCookiePreferences,
  } = useConsentManager();

  // Manage modal state in real-time
  const [preferences, setPreferences] = useState(() => {
    return cookiePreferences?.consent?.categoryPreferences || defaultPreferences;
  });

  const isConsentRequired = React.useMemo(() => {
    if (isAuthenticated) return false;

    return inRegions(["CA", "EU"])();
  }, [isAuthenticated]);
  const isInCA = React.useMemo(() => inRegions(["CA"])(), []);
  const isInEU = React.useMemo(() => inRegions(["EU"])(), []);

  const defaultRegionPreferences = isInCA
    ? caDefaultPreferences
    : isInEU
      ? euDefaultPreferences
      : defaultPreferences;

  // Initialize preferences based on region if the cookie does not exist
  useEffect(() => {
    const storedPreferences = cookiePreferences?.consent?.categoryPreferences;
    if (!storedPreferences) {
      const initialPrefs = isInCA
        ? caDefaultPreferences
        : isInEU
          ? euDefaultPreferences
          : defaultPreferences;

      setPreferences(initialPrefs);

      const consentPreferences = {
        advertising: initialPrefs.advertising,
        marketingAndAnalytics: initialPrefs.marketingAndAnalytics,
        functional: initialPrefs.functional,
      };

      setCookiePreferences({
        version: 1,
        pristine: true,
        consent: {
          categoryPreferences: consentPreferences,
        },
      });
    }
  }, [isConsentRequired, isInCA, isInEU, cookiePreferences, setCookiePreferences]);

  const handleConsentChange = (key, value) => {
    setPreferences((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSavePreferences = () => {
    const consentPreferences = {
      advertising: preferences.advertising,
      marketingAndAnalytics: preferences.marketingAndAnalytics,
      functional: preferences.functional,
    };

    setCookiePreferences({
      version: 1,
      pristine: false,
      consent: {
        categoryPreferences: consentPreferences,
      },
    });

    // Track consent update in Segment
    analytics.track("Consent Preferences Updated", {}, {
      context: {
        consent: {
          categoryPreferences: consentPreferences,
        },
      },
    });

    closeConsentBanner();

    return closePreferencesDialog();
  };

  /**
   * Cancel or close will result in using the existing saved preferences,
   * or in restricted regions, the default preferences of that region.
   */
  const handleCancel = () => {
    const userPreferences = cookiePreferences?.consent?.categoryPreferences || defaultRegionPreferences;
    setPreferences(userPreferences);

    const consentPreferences = {
      advertising: userPreferences.advertising,
      marketingAndAnalytics: userPreferences.marketingAndAnalytics,
      functional: userPreferences.functional,
    };

    setCookiePreferences({
      version: 1,
      pristine: false,
      consent: {
        categoryPreferences: consentPreferences,
      },
    });

    // Track consent update in Segment
    analytics.track("Consent Preferences Updated", {}, {
      context: {
        consent: {
          categoryPreferences: consentPreferences,
        },
      },
    });

    closeConsentBanner();

    return closePreferencesDialog();
  };

  if (!showPreferencesDialog) return null;

  return (
    <Grommet theme={themeStyle} style={{ width: "100%" }}>
      <Modal
        onEsc={handleCancel}
        onClose={handleCancel}
        onClickOutside={handleCancel}
      >
        <Box pad="medium" gap="medium" style={{ width: "680px" }}>
          <Heading size="small" margin="none">
            {preferencesDialogTitle}
          </Heading>
          <Text>{preferencesDialogContent}</Text>
          <Text>
            By using our website, you’re agreeing to our{" "}
            <AnchorLink
              href="https://cloverleaf.me/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </AnchorLink>
          </Text>
          <Text>
            The table below outlines how we use this data by category. To opt
            out of a category of data collection, select “No” and save your
            preferences.
          </Text>
          <Box overflow={{ vertical: "auto" }}>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell
                    scope="col"
                    border="bottom"
                    style={{ fontWeight: "bold", paddingBottom: "10px" }}
                  >
                    Allow
                  </TableCell>
                  <TableCell
                    scope="col"
                    border="bottom"
                    style={{ fontWeight: "bold", paddingBottom: "10px" }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    scope="col"
                    border="bottom"
                    style={{ fontWeight: "bold", paddingBottom: "10px" }}
                  >
                    Purpose
                  </TableCell>
                  <TableCell
                    scope="col"
                    border="bottom"
                    style={{ fontWeight: "bold", paddingBottom: "10px" }}
                  >
                    Tools
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {CONSENT_PREFERENCES.map((row) => (
                  <TableRow
                    key={row.key}
                    style={{ borderBottom: "1px solid #E0E0E0" }}
                  >
                    <TableCell style={{ padding: "10px" }}>
                      <RadioButtonGroup
                        id={row.key}
                        name={row.key}
                        options={["yes", "no"]}
                        value={preferences[row.key] ? "yes" : "no"}
                        onChange={(event) =>
                          handleConsentChange(
                            row.key,
                            event.target.value === "yes"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      style={{ padding: "10px", verticalAlign: "top" }}
                    >
                      <Text size="small">{row.category}</Text>
                    </TableCell>
                    <TableCell
                      style={{ padding: "10px", verticalAlign: "top" }}
                    >
                      <Text size="small">{row.purpose}</Text>
                    </TableCell>
                    <TableCell
                      style={{ padding: "10px", verticalAlign: "top" }}
                    >
                      <Text size="small">{row.tools}</Text>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow style={{ borderBottom: "1px solid #E0E0E0" }}>
                  <TableCell style={{ padding: "10px" }}>
                    <Text size="small">N/A</Text>
                  </TableCell>
                  <TableCell
                    style={{ padding: "10px", verticalAlign: "top" }}
                  >
                    <Text size="small">Essential</Text>
                  </TableCell>
                  <TableCell
                    style={{ padding: "10px", verticalAlign: "top" }}
                  >
                    <Text size="small">
                      We use browser cookies that are necessary for the site to
                      work as intended. For example, we store your website data
                      collection preferences so we can honor them if you return
                      to our site. You can disable these cookies in your browser
                      settings but if you do the site may not work as intended.
                    </Text>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box direction="row" justify="end" gap="small" flex={false}>
            <Button secondary label="Cancel" onClick={handleCancel} />
            <Button primary label="Save" onClick={handleSavePreferences} />
          </Box>
        </Box>
      </Modal>
    </Grommet>
  );
};

export { ConsentManager };
