/**
 * https://www.npmjs.com/package/@segment/consent-manager#closebehavior
 */
export const CLOSE_BEHAVIOR_OPTIONS = {
  ACCEPT: "accept", // assume consent across every category
  DENY: "deny", // denies consent across every category
  DISMISS: "dismiss", // dismisses the banner, but doesn't save or change any preferences. Analytics.js won’t load until consent is given.
};

/**
 * https://www.npmjs.com/package/@segment/consent-manager#defaultdestinationbehavior
 */
export const DESTINATION_BEHAVIOR_OPTIONS = {
  IMPLY: "imply",
  DISABLE: "disable",
  ENABLE: "enable",
};

export const bannerContent = (
  <span>
    We use cookies (and other similar technologies) to collect data to improve your experience on
    our site. By using our website, you’re agreeing to the collection of data as described in our{" "}
    <a href="https://cloverleaf.me/privacy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
    .
  </span>
);

export const bannerSubContent = "You can change your preferences at any time.";

export const preferencesDialogTitle = "Website Data Collection Preferences";

export const preferencesDialogContent = "Segment uses data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.";

export const cancelDialogTitle = "Are you sure you want to cancel?";

export const cancelDialogContent = "Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Privacy Policy.";

export const CATEGORIES = {
  functional: "functional",
  marketingAndAnalytics: "marketingAndAnalytics",
  advertising: "advertising",
};

export const defaultPreferences = {
  [CATEGORIES.functional]: true,
  [CATEGORIES.marketingAndAnalytics]: true,
  [CATEGORIES.advertising]: true,
};

export const caDefaultPreferences = {
  [CATEGORIES.functional]: true,
  [CATEGORIES.marketingAndAnalytics]: true,
  [CATEGORIES.advertising]: false,
};

export const euDefaultPreferences = {
  [CATEGORIES.functional]: false,
  [CATEGORIES.marketingAndAnalytics]: false,
  [CATEGORIES.advertising]: false,
};

// List of all potential tools (integrations) that may be loaded upon consent
export const potentialTools = {
  [CATEGORIES.functional]: ["Intercom"],
  [CATEGORIES.marketingAndAnalytics]: ["Amplitude", "Mixpanel", "SendGrid"],
  [CATEGORIES.advertising]: ["Facebook Ads", "Google Ads"],
};

export const CONSENT_PREFERENCES = [
  {
    key: CATEGORIES.functional,
    category: "Functional",
    purpose:
      "To monitor the performance of our site and to enhance your browsing experience. For example, these tools enable you to communicate with us via live chat.",
    tools: potentialTools.functional.join(", "),
  },
  {
    key: CATEGORIES.marketingAndAnalytics,
    category: "Marketing and Analytics",
    purpose:
      "To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site. For example, we collect information about which pages you visit to help us present more relevant information.",
    tools: potentialTools.marketingAndAnalytics.join(", "),
  },
  {
    key: CATEGORIES.advertising,
    category: "Advertising",
    purpose:
      "To personalize and measure the effectiveness of advertising on our site and other websites. For example, we may serve you a personalized ad based on the pages you visit on our site.",
    tools: potentialTools.advertising.join(", "),
  },
];
